let highlights = document.querySelectorAll('.highlight')

if(highlights){

  function toggleClassDesactive(){
    highlights.forEach(highlight=>{highlight.classList.toggle('js-desactive')})
  }

  highlights.forEach(item =>{
    item.onmouseover = (event) => {
      toggleClassDesactive()
      item.classList.toggle('js-desactive')
    }

    item.onmouseout = (event) => {
      item.classList.toggle('js-desactive')
      toggleClassDesactive()
    }
  })
}
