document.addEventListener('DOMContentLoaded', function (event) {
  const menuIcon = document.getElementById('controlMenu')

  const listenerCheckPlace = function (event) {
    (event.target.checked) ? desactiveMenu() : activeMenu()
  }

  const activeMenu = function () {
    disableScroll()
  }

  const desactiveMenu = function () {
    enableScroll()
  }

  function disableScroll(){  
    document.body.style.overflow = "hidden";
    document.body.style.userSelect = "none";
  }
  
  function enableScroll(){  
    document.body.style.overflow = "auto";
    document.body.style.userSelect = "auto";
  }
  menuIcon.addEventListener('change', listenerCheckPlace)
})